import React, { useEffect, useState } from 'react';
import { Link } from "gatsby";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import auth0 from 'auth0-js';
import LogoutButton from "../components/auth0/LogOut";

import Authenticator from "../components/auth0/Authenticator";


const Account = () => {
  /* 👇 Access user from the useAuth0 hook 👇 */
  const { user, isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();
  const [userMetadata, setUserMetadata] = useState(null);


  //console.log("isLoading", isLoading)

  const Authenti = Authenticator();

  //console.log("Authenti", Authenti)

  //var access_token = "";

  //if (typeof window !== 'undefined') {

  //  access_token = localStorage.getItem('access_token');

  //  if ((access_token == null) || (access_token === "") || (typeof access_token === "undefined")) {
  //    window.location.href = '/login';
  //  }
  //}

    useEffect(() => {

      const getUserMetadata = async () => {
        const domain = "boringmoney.uk.auth0.com";

        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: `https://${domain}/api/v2/`,
              scope: "read:current_user",
            },
          });

          const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;

          const metadataResponse = await fetch(userDetailsByIdUrl, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          const { user_metadata } = await metadataResponse.json();

          setUserMetadata(user_metadata);
        } catch (e) {
          //console.log(e.message);
          // window.location.href = '/login';

        }
      };

      getUserMetadata();

    }, [user]);
  
  
  
  if (Authenti) {
    return (
      <>
        
        <nav>
          {/* 👇 Link to homepage */} 👇
          <Link to="/">Home</Link>
          <p>Hi {user?.nickname}</p>
          <p>Email: {user?.email}</p>
          <h3>User Metadata</h3>
          {userMetadata ? (
            <>
              <p>{userMetadata.braze_id}</p>
            </>

          ) : (
            "No user metadata defined"
          )}
          <LogoutButton />


        </nav>
      </>
    );
  }
  else {
    return null;
  }
 
  
};

/* 👇 Wrap the component in the withAuthenticationRequired handler 👇 */
//export default withAuthenticationRequired(Account, {
//  onRedirecting: () => {
//    window.location.href = "http://localhost:8000/login";
//    return null; // Return null to prevent the component from rendering while the user is being redirected
//  },
//});
export default Account;
